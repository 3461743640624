import React, { Component } from 'react';
import OrgChart from './orgchart'; // Adjust the path based on where OrgChart.js is located
import './OrgChartStyles.css'; // Make sure to include your custom styles

export default class Tree extends Component {

    constructor(props) {
        super(props);
        this.divRef = React.createRef(); // Reference to the DOM element for the chart
    }

    // Prevent component re-rendering to avoid OrgChart reinitialization
    shouldComponentUpdate() {
        return false;
    }

    componentDidMount() {
        // Define the custom "ana" template
        OrgChart.templates.ana.plus = 
            `<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>
            <text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>`;

        // Define "itTemplate" based on "ana"
        OrgChart.templates.itTemplate = Object.assign({}, OrgChart.templates.ana);
        OrgChart.elements.myTextArea = function (data, editElement, minWidth, readOnly) {
            var id = OrgChart.elements.generateId();
            var value = data[editElement.binding];
            if (value == undefined) value = '';
            if (readOnly && !value) {
                return {
                    html: ''
                };
            }
            var rOnlyAttr = readOnly ? 'readonly' : '';
            var rDisabledAttr = readOnly ? 'disabled' : '';
            return {
                html: `<label for="${id}">${editElement.label}</label>
                              <textarea ${rDisabledAttr} ${rOnlyAttr} id="${id}" name="${id}" style="width: 120%;height: 200px;" data-binding="${editElement.binding}">${value}</textarea>`,
                id: id,
                value: value
            };
        
        };
        OrgChart.templates.itTemplate.nodeMenuButton = "";
        OrgChart.templates.itTemplate.nodeCircleMenuButton = {
            radius: 18,
            x: 250,
            y: 60,
            color: '#fff',
            stroke: '#aeaeae'
        };

        // Define the invisible group template
        OrgChart.templates.invisibleGroup.padding = [0, 0, 0, 0];
        OrgChart.templates.invisibleGroup.size = [0, 0];

        // Set up chart options
        let options = this.getOptions();

        // Initialize OrgChart
        this.chart = new OrgChart(this.divRef.current, {
            mouseScrool: OrgChart.action.ctrlZoom,
            scaleInitial: options.scaleInitial,
            enableSearch: options.enableSearch,
            orientation: OrgChart.orientation.bottom,
            template: "ana", // Set the "ana" template as default
            enableDragDrop: false,
            assistantSeparation: 170,

            
            levelSeparation: 200,
            align: OrgChart.ORIENTATION,

            toolbar: {
                fullScreen: true,
                zoom: true,
                fit: true,
                expandAll: true
            },

            nodeBinding: {
                field_0: "name",
                field_1: "title",
                field_2: "description",
                field_3: "email",
                field_4: "telefon",
                field_5: "gol",   
                field_6: "gol2",   
                img_0: "img"
            },
            editForm: {
                photoBinding: "img",
                generateElementsFromFields: false,
                elements: [
                    { type: 'textbox', label: 'Nume', binding: 'name' },
                    { type: 'textbox', label: 'Functie Interna', binding: 'title' },
                    { type: 'textbox', label: '', binding: 'gol2' },
                    { type: 'textbox', label: 'Email', binding: 'email' },
                    { type: 'textbox', label: 'Telefon', binding: 'telefon' },
                    { type: 'textbox', label: 'Descriere', binding: 'gol' },
                    { type: 'myTextArea', label: ' ', binding: 'description' },
                    
                ],
                buttons:  {
                    edit: null,
                    share: null,
                    pdf: null,
                    remove: null,
                }
        
            },
            tags: {
                "top-management": {
                    template: "invisibleGroup",
                    subTreeConfig: {
                        orientation: OrgChart.orientation.top,
                        collapse: {
                            level: 1
                        }
                    }
                },
                "Dezvoltare-Inovare": {
                    subTreeConfig: {
                        layout: OrgChart.treeLeftOffset,
                        levelSeparation: 100,
                        collapse: {
                            level: 1
                        }
                    }
                },
                "Consilieri-Management": {
                    subTreeConfig: {
                        layout: OrgChart.treeRightOffset,
                        levelSeparation: 100,
                        collapse: {
                            level: 1
                        }
                    }
                },
                "Cultura-Organizationala": {
                    subTreeConfig: {
                        layout: OrgChart.treeLeftOffset,
                        levelSeparation: 100,
                        collapse: {
                            level: 1
                        }
                    }
                },
                "Implementare-Proiecte": {
                    subTreeConfig: {
                        layout: OrgChart.treeRightOffset,
                        levelSeparation: 100,
                        collapse: {
                            level: 1
                        }
                    }
                },
                "Suport-Proiecte": {
                    subTreeConfig: {
                        layout: OrgChart.treeRightOffset,
                        levelSeparation: 100,
                        collapse: {
                            level: 1
                        }
                    }
                },
                "seo-menu": {
                    nodeMenu: {
                        addSharholder: { text: "Add new shareholder", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: this.addSharholder },
                        addDepartment: { text: "Add new department", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: this.addDepartment },
                        addAssistant: { text: "Add new assistant", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: this.addAssistant },
                        edit: { text: "Edit" },
                        details: { text: "Details" }
                    }
                },
                "menu-without-add": {
                    nodeMenu: {
                        details: { text: "Details" },
                        edit: { text: "Edit" },
                        remove: { text: "Remove" }
                    }
                },
                
                "department": {
                    template: "group",
                    nodeMenu: {
                        addManager: { text: "Add new manager", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: this.addManager },
                        remove: { text: "Remove department" },
                        edit: { text: "Edit department" },
                        nodePdfPreview: { text: "Export department to PDF", icon: OrgChart.icon.pdf(24, 24, "#7A7A7A"), onClick: this.nodePdfPreview }
                    }
                },
                "Dezvoltare-Inovare-member": {
                    template: "itTemplate"
                }
            },

            clinks: [
                { from: 11, to: 18 }
            ]
        });

        // Load the nodes
        if (this.props.nodes && this.props.nodes.length) {
            this.chart.load(this.props.nodes);
        }

        // Add event handlers for nodeCircleMenu
        this.chart.nodeCircleMenuUI.on('click', (sender, args) => {
            switch (args.menuItem.text) {
                case "Details": this.chart.editUI.show(args.nodeId, true);
                    break;
                case "Add node": {
                    let id = this.chart.generateId();
                    this.chart.addNode({ id: id, pid: args.nodeId, tags: ["Dezvoltare-Inovare-member"] });
                }
                    break;
                case "Edit node": this.chart.editUI.show(args.nodeId);
                    break;
                case "Remove node": this.chart.removeNode(args.nodeId);
                    break;
                default:
            }
        });

        this.chart.nodeCircleMenuUI.on('drop', (sender, args) => {
            this.chart.addClink(args.from, args.to).draw(OrgChart.action.update);
        });

        // Event when a node is added
        this.chart.on("added", (sender, id) => {
            sender.editUI.show(id);
        });

        // Handle drop events for drag and drop actions
        // this.chart.on('drop', (sender, draggedNodeId, droppedNodeId) => {
        //     let draggedNode = sender.getNode(draggedNodeId);
        //     let droppedNode = sender.getNode(droppedNodeId);
        //     if (droppedNode != undefined) {
        //         if (droppedNode.tags.indexOf("department") != -1 && draggedNode.tags.indexOf("department") == -1) {
        //             let draggedNodeData = sender.get(draggedNode.id);
        //             draggedNodeData.pid = null;
        //             draggedNodeData.stpid = droppedNode.id;
        //             sender.updateNode(draggedNodeData);
        //             return false;
        //         }
        //     }
        // });

        // Customize export styles
        this.chart.on('exportstart', (sender, args) => {
            args.styles = document.getElementById('myStyles').outerHTML;
        });
    }

    preview = () => {
        OrgChart.pdfPrevUI.show(this.chart, { format: 'A4' });
    }

    nodePdfPreview = (nodeId) => {
        OrgChart.pdfPrevUI.show(this.chart, { format: 'A4', nodeId: nodeId });
    }

    addSharholder = (nodeId) => {
        this.chart.addNode({ id: OrgChart.randomId(), pid: nodeId, tags: ["menu-without-add"] });
    }

    addAssistant = (nodeId) => {
        let node = this.chart.getNode(nodeId);
        let data = { id: OrgChart.randomId(), pid: node.stParent.id, tags: ["assistant"] };
        this.chart.addNode(data);
    }

    addDepartment = (nodeId) => {
        let node = this.chart.getNode(nodeId);
        let data = { id: OrgChart.randomId(), pid: node.stParent.id, tags: ["department"] };
        this.chart.addNode(data);
    }

    addManager = (nodeId) => {
        this.chart.addNode({ id: OrgChart.randomId(), stpid: nodeId });
    }

    getOptions = () => {
        const searchParams = new URLSearchParams(window.location.search);
        let fit = searchParams.get('fit');
        let enableSearch = true;
        let scaleInitial = 1;
        if (fit === 'yes') {
            enableSearch = false;
            scaleInitial = OrgChart.match.boundary;
        }
        return { enableSearch, scaleInitial };
    }

    render() {
        return (
            <div id="tree" ref={this.divRef} style={{ width: '100%', height: '100%' }}></div>
        );
    }
}
